import { render, staticRenderFns } from "./PixelLibraryTable.vue?vue&type=template&id=55a42faa&scoped=true&"
import script from "./PixelLibraryTable.vue?vue&type=script&lang=js&"
export * from "./PixelLibraryTable.vue?vue&type=script&lang=js&"
import style0 from "./PixelLibraryTable.vue?vue&type=style&index=0&id=55a42faa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a42faa",
  null
  
)

export default component.exports