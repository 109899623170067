<template>
  <div>
    <h3>Web Pixels</h3>
    <pixel-library-table />
  </div>
</template>

<script>
import PixelLibraryTable from '../../pixel/PixelLibraryTable';

export default {
  components: {
    PixelLibraryTable,
  },

  data() {
    return {};
  },
};
</script>
