<template>
  <div>
    <el-button
      v-if="isInternal || hasPermission('create_pixels')"
      type="primary"
      @click="showEditDialog = true"
    >
      <i class="fa fa-plus" /> Add New Pixel
    </el-button>
    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :data-class="pixel"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      :page-size="dataTable.pageSize"
      disable-archive
      disable-checkboxes
      @action="handleAction"
      @data-fetch="handleDataFetch"
    >
      <el-table-column
        prop="name"
        label="Pixel"
        sortable="custom"
      />
      <el-table-column
        prop="status"
        label="Status"
        sortable="custom"
      >
        <template slot-scope="data">
          <i
            v-if="!data.row.pixel_id"
            class="fa fa-sync fa-spin"
          />
          <el-tag
            v-else
            size="mini"
            :type="statusType(data.row.status)"
          >
            {{ data.row.status | statusLabel }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="tags"
        label="Tags"
      >
        <template slot-scope="data">
          <el-tag
            v-for="tag in data.row.tags"
            :key="tag.id"
            size="mini"
          >
            {{ tag.name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="url"
        label="Destination URL"
        sortable="custom"
      />
      <el-table-column
        v-if="isInternal"
        label="Pixel ID"
      >
        <template slot-scope="{ row }">
          {{ row.pixel_id }}
        </template>
      </el-table-column>
      <el-table-column
        label="Embed"
        width="75px"
      >
        <template slot-scope="{ row }">
          <el-button
            v-clipboard:copy="row.embed_code"
            v-clipboard:success="() => afterCopy(row)"
            :disabled="!row.embed_code"
            icon="fa fa-lg fa-clipboard-list"
            plain
            size="mini"
            type="primary"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="organization"
        label="Organization"
        sortable="custom"
        :width="150"
      >
        <template slot-scope="data">
          {{ data.row.organization.name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="Created"
        sortable="custom"
        :width="110"
      >
        <template slot-scope="data">
          {{ data.row.created_at | dateString }}
        </template>
      </el-table-column>
      <el-table-column
        prop="author"
        label="Author"
        sortable="custom"
        :width="130"
      >
        <template slot-scope="data">
          {{ data.row.author | fullName }}
        </template>
      </el-table-column>
    </data-table>

    <pixel-audience-form
      :visible.sync="showAudienceDialog"
      :pixel-id="selectedPixel"
    />

    <pixel-form-dialog
      :visible.sync="showEditDialog"
      @save="handleSave"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DataTable from '../global/DataTable.vue';
import PixelAudienceForm from '@/components/pixel/PixelAudienceForm.vue';
import PixelFormDialog from '@/components/pixel/PixelFormDialog.vue';
import { pixel as pixelApi } from '@/adonis-api';
import jobWatcher from '@/mixins/job-watcher';
import { getPixelMenu } from '@/menus';
import { getValidationErrors } from '@/helpers/validation-rules';

export default {
  components: {
    DataTable,
    PixelAudienceForm,
    PixelFormDialog,
  },

  filters: {
    statusLabel: function (value) {
      switch (value) {
        case 'ACTIVE':
          return 'Active';
        case 'INACTIVE':
          return 'Inactive';
        case 'AWAITING_DATA':
        default:
          return 'Awaiting data';
      }
    },
  },

  mixins: [jobWatcher],

  data() {
    return {
      pixelData: {
        data: [],
        page: '1',
        perPage: '25',
        total: 0,
      },
      busy: false,
      dataTable: Object.freeze({
        actions: getPixelMenu(this),
        order: 'desc',
        sortBy: 'created_at',
      }),
      selectedPixel: null,
      showEditDialog: false,
      showAudienceDialog: false,
    };
  },

  computed: {
    ...mapGetters('user', [
      'hasHistoricalAccess',
      'permissions',
      'isInternal',
      'hasPermission',
    ]),
    ...mapState('audience', ['cloneJobId', 'dialog']),
    ...mapState('app', ['lastPageNum']),
  },

  watch: {
    watchedJobs: {
      handler(jobs) {
        const completedJobs = jobs.filter(
          job => job.payload.status === 'COMPLETE',
        );

        if (!completedJobs.length) return;

        for (const { id, pixelId, embedCode } of completedJobs.map(job => ({
          id: job.payload.result.pixel.id,
          pixelId: job.payload.result.pixel.pixel_id,
          embedCode: job.payload.result.pixel.embed_code,
        }))) {
          this.$refs.dataTable.updateItemById(id, {
            pixel_id: pixelId,
            embed_code: embedCode,
          });
        }
      },
      deep: true,
    },
  },

  created() {
    this.pixel = pixelApi;
  },

  methods: {
    afterCopy(pixel) {
      this.$message({
        message: `Pixel embed code copied to clipboard. Please add it to ${pixel.url}`,
        type: 'success',
      });
    },
    statusType (value) {
      switch (value) {
        case 'ACTIVE':
          return 'success';
        case 'INACTIVE':
          return 'info';
        case 'AWAITING_DATA':
        default:
          return 'warning';
      }
    },

    async handleAction(command, pixel, options = {}) {
      switch (command) {
        case 'audience':
          this.selectedPixel = pixel.id;
          this.showAudienceDialog = true;
          break;
        case 'enable':
          this.togglePixels({ ids: [pixel.id], on: true });
          this.$message('Pixel enabled.');
          break;
        case 'disable':
          this.$confirm(
            `Are you sure you want to disable the pixel "${
              pixel.name
            }"?` + ' No data will be ingested for this pixel if disabled.',
            'Disable Pixel',
          )
            .then(() => this.togglePixels({ ids: [pixel.id], on: false }))
            .then(() => {
              this.$message('Pixel disabled.');
            })
            .catch(err => {
              if (err !== 'cancel') {
                this.$reportError(err);
              }
            });
          break;
        case 'viewDetails':
          this.$router.push(`/data-source/pixels/${pixel.id}`);
          break;

        default:
          break;
      }
    },

    handleDataFetch(pixels) {
      const pixelJobIds = pixels.filter(p => p.job).map(p => p.job.id).filter(id => id);
      this.setWatchedJobs(pixelJobIds);
    },

    async handleSave(payload) {
      this.$message({
        message: 'Requesting Pixel',
        customClass: 'el-message--info',
        iconClass: 'el-message__icon fa fa-clock',
        duration: 3000,
      });

      this.showEditDialog = false;
      try {
        const response = await pixelApi.create({
          organization_id: payload.organization.id,
          tags: payload.tags,
          auto_dedupe: payload.auto_dedupe,
          name: payload.name,
          url: payload.url,
          pixel_partners: payload.pixel_partners,
        });
        this.$store.dispatch('mqtt/addRawJob', response.data);
        this.watchJob(response.data.id);
        window.mixpanel.track('Create Pixel', {
          Name: payload.name,
        });

        // Reload page 1 results.
        this.$refs.dataTable.handlePageChange(1);
      } catch (e) {
        if (e.response) this.$emit('error', getValidationErrors(e));
        else {
          this.$notify.error({
            message: 'There was a problem requesting this pixel. This issue has been reported.',
          });
          this.$reportError(e, {
            componentName: this.$options.name,
            formData: payload,
          });
        }
      }
    },

    tagDisplay(row) {
      return row.tags ? row.tags.join(', ') : '';
    },

    async togglePixels(args) {
      if (args.on) {
        pixelApi.activate(args.ids);
      } else {
        pixelApi.deactivate(args.ids);
      }

      args.ids.map(id => {
        this.$refs.dataTable.updateItemById(id, {
          active: args.on,
          status: args.on ? 'AWAITING_DATA' : 'INACTIVE',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: inline-block;
  margin: 16px 0;
}

.search-box {
  max-width: 180px;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin-left: 4px;
}

.outdated-tag {
  font-size: 10px;
  padding: 0 2px;
  height: 16px;
  line-height: 15px;
}

.controls {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  width: 50%;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
