<template>
  <edit-dialog
    ref="editDialog"
    :data="pixel"
    :el-rules="elementRules"
    object-name="Web Pixel"
    label-width="150px"
    :visible="visible"
    width="50%"
    @request-close="$emit('update:visible', false)"
    @save="data => $emit('save', data)"
  >
    <template slot-scope="{ formData, error }">
      <div>
        <div v-show="error">
          <el-alert type="error">
            {{ error }}
          </el-alert>
        </div>

        <div class="form">
          <el-form-item
            label="Pixel Name"
            prop="name"
          >
            <el-input v-model="formData.name" />
            <div>
              <dedupe-control v-model="formData.auto_dedupe" />
            </div>
          </el-form-item>
          <el-form-item
            label="Destination URL"
            prop="url"
          >
            <el-input
              v-model="formData.url"
              placeholder="https://example.com"
            />
          </el-form-item>
          <el-form-item
            label="Organization"
            prop="organization"
          >
            <org-picker
              :organization.sync="formData.organization"
            />
          </el-form-item>
          <el-form-item
            label="Tags"
            prop="tags"
          >
            <tag-picker :tags.sync="formData.tags" />
          </el-form-item>
          <el-card
            v-if="isInternal"
            shadow="never"
            class="internal"
          >
            <div slot="header">
              <span>Pixel Partner Override</span>
            </div>
            <p>
              If set, this will override the default pixel partners for this pixel's organization.
            </p>
            <el-select
              v-model="formData.pixel_partners"
              multiple
            >
              <el-option
                v-for="partner in pixelPartners"
                :key="partner"
                :label="partner"
                :value="partner"
              />
            </el-select>
          </el-card>
        </div>
      </div>
    </template>
  </edit-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import validator from 'validator';
import DedupeControl from '@/components/global/DedupeControl.vue';
import OrgPicker from '@/components/global/OrgPicker.vue';
import EditDialog from '@/components/global/EditDialog.vue';
import TagPicker from '@/components/global/TagPicker.vue';
import { pixelPartners } from '@/constants';

export default {
  components: { DedupeControl, EditDialog, OrgPicker, TagPicker },
  props: {
    visible: { type: Boolean, required: true },
  },

  data() {
    return {
      pixel: {
        organization: { id: this.$store.state.user.orgDetails.id },
        auto_dedupe: this.$store.state.user.userDetails.preferences.options.auto_dedupe,
      },
      elementRules: {
        name: [
          {
            required: true,
            message: 'Pixel name is required.',
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (value.trim().length < 1) {
                callback(new Error('Pixel name is required.'));
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
        url: [
          {
            required: true,
            message: 'Destination URL is required.',
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (value.trim().length < 1) {
                callback(new Error('Destination URL is required.'));
              }
              callback();
            },
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (!validator.isURL(value, {
                protocols: ['http', 'https'],
                require_tld: true,
                require_protocol: true,
                require_host: true,
                require_valid_protocol: true,
                allow_underscores: false,
                host_whitelist: false,
                host_blacklist: false,
                allow_trailing_dot: false,
                allow_protocol_relative_urls: false,
                disallow_auth: false,
              })) {
                callback(new Error('Destination URL must be a valid URL, including the protocol, for example: https://google.com'));
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
        organization: [
          {
            validator(rule, value, callback) {
              if (!value) {
                callback(new Error('Please choose an organization.'));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters('user', ['hasPermission', 'isInternal']),

    pixelPartners() {
      return pixelPartners;
    },
  },

  watch: {
    visible(value) {
      this.$refs.editDialog.reset();
    },
  },

  methods: {
  },
};
</script>

<style scoped>
:deep(.org-picker),
:deep(.tag-picker) {
  width: calc(100% - 150px) !important;
  display: inline-block;
}

.el-input {
  width: 320px;
}
</style>
